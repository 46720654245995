import React from "react";
import { styled } from "../../../stitches.config";
import { routes } from "../../routes/routes";
import { Article } from "../../types";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { formatDate } from "../../utils";
import { CategoryIndicator } from "../News/CategoryIndicator";

type Props = {
  articles: Article[];
};

const placeholders = ["", "", ""];
const createShortDescription = (item: string) => {
  const richDescriptionObj = eval("({obj:[" + item + "]})");

  const shortArticleInfo = richDescriptionObj.obj[0].blocks.find(
    (el: any) => el.type === "paragraph"
  );

  const textShortCut = shortArticleInfo.data.text.slice(0, 102) + "...";

  return textShortCut;
};

export const NewsBlock = ({ articles }: Props) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  return (
    <NewsSection>
      <NewsHead>
        <NewsTitle>{t("monthNews")}</NewsTitle>
        <ViewAllBtn to={routes.news.news}>{t("viewAll")}</ViewAllBtn>
      </NewsHead>
      <NewsList>
        {!!articles.length
          ? articles.map((item) => (
              <ListEl
                onClick={() => navigate(`/news/${item.attributes.slug}`)}
                key={item.id}
              >
                <ImageContainer>
                  <img
                    src={item.attributes.previewImage.data?.attributes.url}
                  />
                </ImageContainer>
                <CardDesc>
                  <DateCategoryWrap>
                    <DateIndicator>
                      {formatDate(item.attributes.publicationDate)}
                    </DateIndicator>
                    {!!item.attributes.category.data && (
                      <CategoryIndicator
                        categoryId={
                          item.attributes.category.data.attributes.categoryId
                        }
                        categoryName={
                          item.attributes.category.data.attributes.categoryName
                        }
                      />
                    )}
                  </DateCategoryWrap>
                  <ArticleTitle>{item.attributes.title}</ArticleTitle>
                  <ArticleText
                    dangerouslySetInnerHTML={{
                      __html: `${createShortDescription(
                        item.attributes.description
                      )}`,
                    }}
                  ></ArticleText>
                </CardDesc>
              </ListEl>
            ))
          : placeholders.map((_, i) => (
              <ItemPlaceholder key={i}>
                <ImagePlaceholder />
                <PlaceholderLine size={"m"} />
                <PlaceholderLine />
                <PlaceholderLine size={"l"} />
              </ItemPlaceholder>
            ))}
      </NewsList>
    </NewsSection>
  );
};

const NewsSection = styled("section", {
  padding: "32px 0 32px",
  "@md": {
    padding: "60px 0 60px",
  },
});

const NewsHead = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  color: "$text",
  padding: "0 16px",
  maxWidth: "1280px",
  margin: "0 auto",
  "@md": {
    padding: 0,
  },
});

const NewsTitle = styled("h2", {
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 700,
  margin: 0,
  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});

const ViewAllBtn = styled(Link, {
  textDecoration: "none",
  color: "$text",
  fontSize: "17px",
  cursor: "pointer",
  lineHeight: "24px",
  fontWeight: 700,
  transition: "all, 0.5s ease",
  "&:hover": {
    color: "$accent",
  },
});

const NewsList = styled("ul", {
  display: "flex",
  listStyle: "none",
  padding: "0 16px",
  overflowX: "auto",
  overflowY: "hidden",
  maxWidth: "1280px",
  margin: "12px auto 0",
  backgroundColor: "transparent",
  "@md": {
    maxWidth: 1300,
    padding: "16px 0px",
  },
});

const ImageContainer = styled("div", {
  height: "252px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: "7px",
  borderTopLeftRadius: "7px",
  "& > img": {
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
});
const ArticleTitle = styled("span", {
  lineHeight: "24px",
  fontSize: "20px",
  color: "$text",
  fontWeight: 600,
  display: "block",
  marginBottom: "8px",
  transition: "color 0.5s ease",
});

const ListEl = styled("li", {
  border: "1px solid $secondaryBackground",
  borderRadius: "8px",
  minWidth: "420px",
  cursor: "pointer",
  height: "468px",
  overflow: "hidden",
  "&:not(:last-child)": {
    marginRight: "16px",
  },
  transition: "all 0.5s ease",
  "@md": {
    minWidth: "unset",
    marginRight: "0px",
    flexBasis: "calc((100% - 100px) / 3)",

    "&:not(:last-child)": {
      marginRight: "40px",
    },
    "&:first-child": {
      marginLeft: "10px",
    },
    "&:last-child": {
      marginRight: "10px",
    },
  },
  "&:hover": {
    boxShadow: "0px 4px 10px rgba(53, 56, 63, 0.1)",
    backgroundColor: "$primaryBackground",
    opacity: 0.9,
  },
});

const ArticleText = styled("span", {
  lineHeight: "24px",
  fontSize: "17px",
  color: "$text",
  fontWeight: 400,
});

const CardDesc = styled("div", {
  padding: "24px",
});

const ItemPlaceholder = styled("li", {
  borderRadius: "8px",
  minWidth: "unset",
  cursor: "pointer",
  height: "436px",
  "&:not(:last-child)": {
    marginRight: "16px",
  },
  "@md": {
    width: "400px !important",
    marginRight: "0px",
    flexBasis: "calc((80% - 100px) / 3)",
    height: "442px",
    "&:not(:last-child)": {
      marginRight: "40px",
    },
    "&:first-child": {
      marginLeft: "10px",
    },
    "&:last-child": {
      marginRight: "10px",
    },
  },
});

const ImagePlaceholder = styled("div", {
  backgroundColor: "$secondaryBackground",
  height: "252px",
  borderTopRightRadius: "7px",
  borderTopLeftRadius: "7px",
});

const PlaceholderLine = styled("div", {
  height: "24px",
  width: "240px",
  marginTop: "20px",
  backgroundColor: "$secondaryBackground",
  variants: {
    size: {
      m: {
        width: "340px",
      },
      l: {
        width: "380px",
        marginBottom: "26px",
      },
    },
  },
});

const DateIndicator = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "20px",
  color: "$articleDate",
});

const DateCategoryWrap = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 12,
});
