import { css, CSS, VariantProps } from "@stitches/react";
import React, { useCallback, useMemo, useState } from "react";
import { styled } from "../../../stitches.config";
import { Solution } from "../../types";
import { solutionsPerRow } from "./constants";

type VariantKeys = Array<keyof VariantProps<typeof Container>>;
type Variants = Record<keyof VariantProps<typeof Container>, boolean>;

function getVariants(arrayLength: number, index: number): Variants {
  const variants: VariantKeys = [];

  if (index === 0) {
    variants.push("hasTopLeftEdge");
  }
  if (index + 1 === Math.min(solutionsPerRow, arrayLength)) {
    variants.push("hasTopRightEdge");
  }

  const indexFromEnd = arrayLength - index;
  const lastRowItems = arrayLength % solutionsPerRow || solutionsPerRow;
  const isLastLine = lastRowItems >= indexFromEnd;
  const isLastLineIsFull = arrayLength % solutionsPerRow === 0;

  if (isLastLine) {
    if (indexFromEnd === 1) {
      variants.push("hasBottomRightEdge");
    }
    if (indexFromEnd === lastRowItems) {
      variants.push("hasBottomLeftEdge");
    }
  }
  if (!isLastLineIsFull) {
    const lastRightBlockIndex =
      arrayLength - (arrayLength % solutionsPerRow) - 1;
    if (lastRightBlockIndex === index) {
      variants.push("hasBottomRightEdge");
    }
  }

  return variants.reduce(
    (acc, next) => ({ ...acc, [next]: true }),
    {} as Variants
  );
}

type Props = {
  solution: Solution;
  index: number;
  totalCount: number;
  onClick: () => void;
  onMouseOver: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    variants: Variants
  ) => void;
  onMouseOut: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export const GridItem: React.FC<Props> = ({
  solution,
  index,
  totalCount,
  onMouseOver,
  onMouseOut,
  onClick,
}) => {
  const variants = useMemo(
    () => getVariants(totalCount, index),
    [totalCount, index]
  );

  const mouseOverHandler = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      return onMouseOver(event, variants);
    },
    [onMouseOver, variants]
  );

  return (
    <Container
      onMouseOver={mouseOverHandler}
      onMouseOut={onMouseOut}
      {...variants}
      onClick={onClick}
    >
      <LogoWrap>
        <img src={solution.attributes.logo.data.attributes.url} />
      </LogoWrap>
      <span>{solution.attributes.name}</span>
      <ShortDescription>
        {solution.attributes.shortDescription}
      </ShortDescription>
    </Container>
  );
};

const ShortDescription = styled("p", {
  marginBottom: 0,
  textAlign: "center",
  "@md": {
    textAlign: "initial",
  },
});

const Container = styled("li", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "12px",
  borderRadius: "8px",
  border: "1px solid $footer",
  margin: "0px 0px 12px 0px",
  alignItems: "center",
  cursor: "pointer",
  "& > span": {
    marginTop: "20px",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "24px",
    color: "$text",
  },
  "@md": {
    margin: 0,
    borderRadius: "0px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "20px",
    height: "380px",
    // all nodes left-top border
    borderWidth: "0 1px 1px 0",
    // first n child top border
    [`&:nth-child(-n+${solutionsPerRow})`]: {
      borderTopWidth: "1px",
    },
    // each first left border
    [`&:nth-child(${solutionsPerRow}n-${solutionsPerRow - 1})`]: {
      borderLeftWidth: "1px",
    },
  },
  variants: {
    hasTopLeftEdge: {
      true: {
        borderTopLeftRadius: "8px",
      },
    },
    hasTopRightEdge: {
      true: {
        borderTopRightRadius: "8px",
      },
    },
    hasBottomLeftEdge: {
      true: {
        borderBottomLeftRadius: "8px",
      },
    },
    hasBottomRightEdge: {
      true: {
        borderBottomRightRadius: "8px",
      },
    },
  },
});

const LogoWrap = styled("div", {
  width: "96px",
  height: "64px",

  "& > img": {
    width: "100%",
    height: "100%",
    borderRadius: 4,
    objectFit: "contain",
    objectPosition: "center",
  },
  "@md": {
    width: "180px",
    height: "120px",
    "& > img": {
      objectPosition: "left",
    },
  },
});
