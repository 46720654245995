import { CSS, VariantProps } from "@stitches/react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useCallback, useMemo, useState } from "react";
import { styled } from "../../../stitches.config";
import { Solution } from "../../types";
import { GridItem } from "./GridItem";

type Props = {
  solutions: Solution[];
};

type Variants = Record<keyof VariantProps<typeof GridItem>, boolean>;

type AnimationBlockStyles = {
  width: number;
  height: number;
  top: number;
  left: number;
  variants: Variants;
};

export const SolutionsGrid = ({ solutions }: Props) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const [animatedBlockStyles, setAnimatedBlockStyles] =
    useState<AnimationBlockStyles>();

  const mouseOverHandler = useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      variants: Variants
    ) => {
      const left = event.currentTarget.offsetLeft;
      const top = event.currentTarget.offsetTop;
      const width = event.currentTarget.clientWidth;
      const height = event.currentTarget.clientHeight;
      setAnimatedBlockStyles({
        left,
        top,
        width,
        height,
        variants,
      });
    },
    [setAnimatedBlockStyles]
  );

  const mouseOutHandler = useCallback(() => {
    setAnimatedBlockStyles(undefined);
  }, [setAnimatedBlockStyles]);

  const gridContainerCss = useMemo<CSS>(() => {
    if (!animatedBlockStyles) {
      return {};
    }
    const { left, top, width, height } = animatedBlockStyles;
    return {
      "@md": {
        "&::before": {
          left,
          top,
          width,
          height,
          display: "block",
        },
      },
    };
  }, [animatedBlockStyles]);

  return (
    <SolutionsSection>
      <SolutionsHeading>{t("solutions")}</SolutionsHeading>
      <Grid css={gridContainerCss} {...animatedBlockStyles?.variants}>
        {solutions.map((solution, i, arr) => {
          return (
            <GridItem
              index={i}
              key={i}
              solution={solution}
              totalCount={arr.length}
              onMouseOut={mouseOutHandler}
              onMouseOver={mouseOverHandler}
              onClick={() => navigate(`/erps-apps/${solution.attributes.slug}`)}
            />
          );
        })}
      </Grid>
    </SolutionsSection>
  );
};

const SolutionsSection = styled("section", {
  padding: "60px 40px 14px",
  maxWidth: "1280px",
  margin: "0 auto",
  "@md": {
    padding: "64px 0",
  },
});

const SolutionsHeading = styled("h2", {
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  color: "$heading",
  textAlign: "center",
  margin: "0px 0px 32px 0px",
  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});

const Grid = styled("ul", {
  display: "grid",
  listStyle: "none",
  padding: 0,
  gridTemplateColumns: "auto",

  "@md": {
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    margin: "0 auto",
    position: "relative",

    "&::before": {
      display: "none",
      width: "428px",
      height: "380px",
      background: "$primaryBackground",
      position: "absolute",
      left: "0px",
      top: "0",
      content: "",
      zIndex: "-1",
      transition: "all 300ms",
    },
  },
  variants: {
    hasTopLeftEdge: {
      true: {
        "@md": {
          "&::before": {
            borderTopLeftRadius: "8px",
          },
        },
      },
    },
    hasTopRightEdge: {
      true: {
        "@md": {
          "&::before": {
            borderTopRightRadius: "8px",
          },
        },
      },
    },
    hasBottomLeftEdge: {
      true: {
        "@md": {
          "&::before": {
            borderBottomLeftRadius: "8px",
          },
        },
      },
    },
    hasBottomRightEdge: {
      true: {
        "@md": {
          "&::before": {
            borderBottomRightRadius: "8px",
          },
        },
      },
    },
  },
});
