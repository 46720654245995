import { keyframes } from "../../stitches.config";

export const BasicSpinAnimation = keyframes({
    "0%": {
        scale: '0.5',
        rotate: '270deg'
    },
    "100%": {
        scale: '1',
        rotate: '360deg'
    },
});