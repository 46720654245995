import React from "react";
import { styled } from "../../../stitches.config";
import Points from "../../images/deco/points.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
const points: string[] = [
  "tbizediSubs.dashboards",
  "tbizediSubs.gateway",
  "tbizediSubs.screenDisplay",
  "tbizediSubs.procurement",
  "tbizediSubs.tbizShops",
  "tbizediSubs.automation",
  "tbizediSubs.monitoring",
  "tbizediSubs.baseData",
];

export const PointsSection = () => {
  const { t } = useTranslation();
  return (
    <SectionContainer>
      <Container>
        <PointsHeading>
          {t("wasIst") + " "}
          <span>{t("points.heading")}</span>?
        </PointsHeading>
        <PointsContainer>
          <div>
            <PointsText>{t("points.description")}</PointsText>
            <PointsList>
              {points.map((item, i) => (
                <li key={i}>
                  <span>{t(item)}</span>
                </li>
              ))}
            </PointsList>
          </div>
          <PointsImgWrap>
            <img src={Points} />
          </PointsImgWrap>
        </PointsContainer>
      </Container>
    </SectionContainer>
  );
};

const SectionContainer = styled("section", {
  padding: "40px 24px",
  backgroundColor: "$primaryBackground",
  "@md": {
    padding: "80px 0",
  },
});

const Container = styled("div", {
  maxWidth: "1280px",
  margin: "0 auto",
});

const PointsHeading = styled("h2", {
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  color: "$heading",
  "&>span": {
    color: "$accent",
  },
  margin: "0px auto 24px 0px",
  "@md": {
    margin: "0px auto 12px 0px",
    fontSize: "24px",
    lineHeight: "28px",
  },
});

const PointsText = styled("span", {
  whiteSpace: "pre-line",
  fontSize: "18px",
  lineHeight: "22px",
  color: "#7A7A7A",
  "@md": { marginTop: "100px !important" },
});

const PointsList = styled("ul", {
  textTransform: "capitalize",
  margin: "24px 0px 0px 0px",
  padding: "0px 0px 0px 16px",
  color: "$text",
  fill: "$text",
  fontSize: "17px",
  fontWeight: 700,
  lineHeight: "24px",
  "& > li": {
    alignItems: "center",
    "&:not(:last-child)": {
      marginBottom: "12px",
    },
    "& > span": {
      marginTop: "2px",
    },
  },
});

const PointsImgWrap = styled("div", {
  display: "none",
  "@md": {
    marginLeft: "114px",
    display: "block",
  },
});

const PointsContainer = styled("div", {
  "@md": {
    display: "flex",
    alignItems: "center",
    "&>div": {
      "&:first-child": {
        paddingTop: "12px",
      },
    },
  },
});
