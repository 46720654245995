import React from "react";
import { styled, keyframes } from "../../../stitches.config";
import { Button } from "../../components/shared/Button";
import { Layout } from "../../layout";
import MainHero from "../../images/background/home-hero.png";
import Lamp from "../../images/background/lamp.png";
import HeroImg from "../../images/deco/homepage.png";
import { NewsBlock, SolutionsGrid } from "../../components/Home/";
import { LiveSupport } from "../../components/shared/LiveSupport";
import { HomeSliderSection } from "../../components/Home/HomeSlider";
import { BasicSpinAnimation } from "../../styles/basicAnimations";
import { PointsSection } from "../../components/Home/PointsSection";
import { Article, Solution, SuccessStory } from "../../types";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { routes } from "../../routes/routes";
import { Seo } from "../../components/shared/Seo";

export type HomePageProps = {
  solutions: Solution[];
  articles: Article[];
  successStories: SuccessStory[];
};

const HomeView: React.FC<HomePageProps> = ({
  articles,
  solutions,
  successStories,
}) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  return (
    <Layout>
      <Seo
        title={t("home.page")}
        description={t("headerSubText")}
        image={HeroImg}
      />
      <Hero>
        <HeroWrap>
          <HeroTextBlock>
            <Heading>{t("homeHeader")}</Heading>
            <HeadingDesc>{t("headerSubText")}</HeadingDesc>
            <Button onClick={() => navigate(routes.contact.contactForm)}>
              {t("contactUs")}
            </Button>
          </HeroTextBlock>
          <ImageWrap>
            <img src={HeroImg} />
          </ImageWrap>
        </HeroWrap>
      </Hero>
      {!!articles.length && <NewsBlock articles={articles} />}
      <PointsSection />
      {!!solutions.length && <SolutionsGrid solutions={solutions} />}
      {/* {!!successStories.length && (
        <SliderSection>
          <SliderHeading>{t("successStories")}</SliderHeading>
          <HomeSliderSection successStories={successStories} />
        </SliderSection>
      )} */}
      <LiveSupport colorScheme="green" />
    </Layout>
  );
};

export default HomeView;

const heroBackgroundKeyframes = keyframes({
  "0%": {
    translate: "70% -100%",
  },
  "100%": {
    translate: "0% 0%",
  },
});

const Hero = styled("section", {
  padding: "64px 16px",
  overflow: "hidden",
  backgroundColor: "$primaryBackground",
  "@md": {
    position: "relative",
    padding: "0px 100px 0px 100px ",
  },
});

const HeroWrap = styled("div", {
  maxWidth: "480px",
  margin: "0 auto",
  "@md": {
    maxWidth: "1280px",
    display: "flex",
  },
});

const Heading = styled("h1", {
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "28px",
  color: "$heading",
  margin: 0,
  position: "relative",
  "@md": {
    fontSize: "48px",
    lineHeight: "56px",
    "&::before": {
      content: "",
      display: "block",
      width: "150px",
      height: "150px",
      position: "absolute",
      animation: `${BasicSpinAnimation} 1.2s ease forwards`,
      background: `url(${Lamp})`,
      opacity: "0.5",
      top: -80,
      left: -110,
    },
  },
});

const HeadingDesc = styled("p", {
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "$text",
  margin: "12px 0px 32px 0px",
  "@md": {
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "28px",
    color: "$text",
    margin: "12px 0px 32px 0px",
  },
});

const HeroTextBlock = styled("div", {
  maxWidth: "480px",
  whiteSpace: "pre-line",
  "@md": {
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
  "@mf": {
    maxWidth: 620,
  },
});

const ImageWrap = styled("div", {
  margin: "64px 0px 0px auto",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  "&>img": {
    maxWidth: "308px",
    maxHeight: "252px",
  },
  "@md": {
    margin: "0px 0px 0px auto",
    padding: "118px 0px 118px 0px",
    zIndex: 4,
    "& > img": {
      minWidth: "450px",
      minHeight: "365px",
    },
    "&::before": {
      zIndex: -1,
      content: "",
      display: "block",
      position: "absolute",
      top: -40,
      left: -440,
      height: "130%",
      width: "1500px",
      backgroundImage: `url(${MainHero})`,
      backgroundSize: "cover",
      backgroundPosition: "410px -860px",
      backgroundRepeat: "no-repeat",
      animation: `${heroBackgroundKeyframes} 1.2s cubic-bezier(.11,.12,.5,1.15) forwards`,
    },
  },
});

const SliderSection = styled("section", {
  padding: "24px 0px 12px 0px",
});

const SliderHeading = styled("h2", {
  color: "$text",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  marginBottom: "12px",
  textAlign: "center",
  margin: "0 auto 40px auto",

  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});
