import * as React from "react";
import { GetServerDataProps, graphql } from "gatsby";
import HomeView, { HomePageProps } from "../views/HomeView/HomeView";
import { sendRequestToAPI } from "../api/graphqlRequest";

const HomePage: React.FC<{ serverData: HomePageProps }> = ({
  serverData: { solutions, successStories, articles },
}) => {
  return (
    <HomeView
      solutions={solutions}
      articles={articles}
      successStories={successStories}
    />
  );
};

export async function getServerData(context: GetServerDataProps) {
  const getData = async () => {
    try {
      const { data } = await sendRequestToAPI(
        `query($locale: I18NLocaleCode){
            successStories(locale:$locale){
            data{
             attributes{
               shortDescription
               representativeName
               longDescription
               companyName,
               companyLogo{data{attributes{url}}}
               representativeAvatar{data{attributes{url}}}
             }
           }
           }
          solutions(locale:$locale, sort:"order:asc"){
            data {
              id
              attributes {
                name
                shortDescription
                slug
              logo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          }
           articles(locale:$locale, pagination:{limit: 3}, sort:"publicationDate:desc"){data{
            id
             attributes{
              publicationDate
               title
               description
               slug
               previewImage{data{attributes{url}}}
               image{data{attributes{url}}}
               category{data{attributes{
                categoryId
                categoryName
               }}}
             }
           }}}
        `,
        { locale: context.pageContext.language },
        {},
        process.env.GATSBY_API_URL
      );
      return data;
    } catch (err) {
      return err;
    }
  };

  const { data } = await getData();
  const serverData = {
    successStories: data.successStories.data,
    solutions: data.solutions.data,
    articles: data.articles.data,
  } as HomePageProps;
  return { props: { ...serverData } };
}

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
